import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { DeviationDisruptionReason } from '@/types/deviation-disruption-reason'
import { getDeviationDisruptionReasons } from '@/api/deviation-disruption-reason/getDeviationDisruptionReasons'
import { deleteDeviationDisruptionReason } from '@/api/deviation-disruption-reason/deleteDeviationDisruptionReason'
import { createDeviationDisruptionReason } from '@/api/deviation-disruption-reason/createDeviationDisruptionReason'
import { updateDeviationDisruptionReason } from '@/api/deviation-disruption-reason/updateDeviationDisruptionReason'

interface UseDeviationDisruptionReason {
  data: Ref<DeviationDisruptionReason[]>
  fetchAll: () => Promise<DeviationDisruptionReason[]>
  remove: (uuid: string) => Promise<null>
  add: (body: DeviationDisruptionReason) => Promise<DeviationDisruptionReason>
  update: (
    body: DeviationDisruptionReason
  ) => Promise<DeviationDisruptionReason>
  loading: ComputedRef<Loading>
}

export function useDeviationDisruptionReason(
  initFetchAll?: boolean
): UseDeviationDisruptionReason {
  const data = ref<DeviationDisruptionReason[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<DeviationDisruptionReason[]>((resolve, reject) => {
      getDeviationDisruptionReasons()
        .then(({ data: reasons }) => {
          data.value = reasons
          resolve(reasons)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteDeviationDisruptionReason(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: DeviationDisruptionReason) {
    state.create = true
    return new Promise<DeviationDisruptionReason>((resolve, reject) => {
      createDeviationDisruptionReason(body)
        .then(({ data: reason }) => {
          data.value.push(reason)
          resolve(reason)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: DeviationDisruptionReason) {
    state.update = true
    return new Promise<DeviationDisruptionReason>((resolve, reject) => {
      updateDeviationDisruptionReason(body)
        .then(({ data: reason }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === reason.uuid) {
              return {
                ...item,
                ...reason,
              }
            }

            return item
          })
          resolve(reason)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
  }
}
