
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useDeviationDisruptionReason } from '@/composable/useDeviationDisruptionReason'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { DeviationDisruptionReason } from '@/types/deviation-disruption-reason'

export default defineComponent({
  name: 'MasterDeviationDisruptionReasons',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateDisruptionReason,
      data: disruptionReasons,
      add,
      update,
      remove,
    } = useDeviationDisruptionReason(true)

    function createFormModel(): Partial<DeviationDisruptionReason> {
      return {
        uuid: uuidv4(),
        name: '',
      }
    }
    const model = ref<Partial<DeviationDisruptionReason>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'name',
        label: 'Orsaksnamn',
        align: 'left',
        field: 'name',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateDisruptionReason.value,
      }
    })

    const exportData = computed(() => {
      return disruptionReasons.value.map((reason) => {
        return {
          Orsaksnamn: reason.name,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      disruptionReasons,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
